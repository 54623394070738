import { useAuthContext } from 'context/auth.context';
import { privateRoutes, RouteInterface } from 'router/routes';

export interface LayoutLinkInterface {
  path: string;
  shortcutPath?: string;
  title: string;
  selected?: boolean;
  external?: boolean;
}

const rawAdminMainLinks: LayoutLinkInterface[] = [
  {
    path: '/admin/tenants',
    title: 'Tenants',
  },
  {
    path: '/admin/projects',
    title: 'Projects',
  },
  {
    path: '/admin/stepTemplates',
    title: 'Templates',
  },
  {
    path: '/admin/media-categories',
    title: 'Media Categories',
  },
  {
    path: '/admin/stores',
    title: 'Stores',
  },
  {
    path: '/admin/brands',
    title: 'Brands',
  },
  {
    path: '/admin/users',
    title: 'Users',
  },
  {
    path: '/admin/groups',
    title: 'Permissions',
  },
  {
    path: '/admin/pillars',
    title: 'Pillars',
  },
  {
    path: '/admin/tag-groups',
    title: 'Tags',
  },
  {
    path: '/admin/bulletins',
    title: 'Bulletins',
  },
];

export const rawUserMainLinks: LayoutLinkInterface[] = [
  {
    path: '/dashboard',
    shortcutPath: '/projects/dashboard',
    title: 'Dashboard',
  },
  {
    path: '/reports',
    shortcutPath: '/projects/reports',
    title: 'Reports',
  },
  {
    path: '/activities',
    shortcutPath: '/projects/activities',
    title: 'Tasks',
  },
  {
    path: '/gallery',
    shortcutPath: '/projects/gallery',
    title: 'Gallery',
  },
];

export const rawMobileOnlyLinks: LayoutLinkInterface[] = [
  {
    path: '/account',
    title: 'Account Settings',
  },
  {
    path: 'https://explore.restoreforretail.com/restore-knowledge-center#subpage/home',
    title: 'Knowledge Hub',
    external: true,
  },
  {
    path: '/content/feedback',
    title: 'Contact Support',
  },
  {
    path: '/content/licence_agreement',
    title: 'Licence Agreement',
  },
  {
    path: '/content/privacy',
    title: 'Privacy Policy',
  },
];

export const rawHeaderLinks = [
  {
    path: '/admin',
    title: 'Admin',
  },
  {
    path: '/projects',
    title: 'My Projects',
  },
];

const useGetPermittedLinks = (links: LayoutLinkInterface[]) => {
  const { hasPermission } = useAuthContext();

  return links.filter((link: LayoutLinkInterface) => {
    const routeLink: RouteInterface | undefined = privateRoutes.find(
      (route) =>
        route.path === link.path ||
        (!!link.shortcutPath && route.shortcutPath === link.shortcutPath)
    );

    return routeLink
      ? !routeLink.permission ||
          hasPermission(
            routeLink.permission.action,
            routeLink.permission.subject,
            routeLink.permission.conditions
          )
      : true;
  });
};

export const useAdminMainLinks = (): LayoutLinkInterface[] =>
  useGetPermittedLinks(rawAdminMainLinks);
export const useUserMainLinks = (): LayoutLinkInterface[] => useGetPermittedLinks(rawUserMainLinks);
export const useMobileOnlyLinks = (): LayoutLinkInterface[] =>
  useGetPermittedLinks(rawMobileOnlyLinks);
export const useHeaderLinks = (selectedLink?: string): LayoutLinkInterface[] => {
  const links = useGetPermittedLinks(rawHeaderLinks);
  return links.map((link) => {
    link.selected = !!selectedLink && link.title === selectedLink;
    return link;
  });
};
