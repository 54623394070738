import { Avatar, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Logout, ArrowForwardIos } from '@mui/icons-material';
import PermissionCheck from '../../components/Abilities/PermissionCheck';
import { getUniqueKey } from '../../utils';
import { privateRoutes } from '../../router/routes';
import { Link } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useAuthContext } from 'context/auth.context';
import { useMobileOnlyLinks } from '../layoutLinks';
import useGetTenantsForCurrentUser from 'hooks/auth/queries/useGetTenantsForCurrentUser';
import { LoginTenant } from '__generated__/graphql';
import TenancyModal from 'components/TenancyModal/TenancyModal';
import CheckIcon from '@mui/icons-material/Check';
import ExternalLinkIcon from 'components/Icon/ExternalLinkIcon';

const settings: string[] = [];

const UserDropdown = styled(Menu)(({ theme }) => ({
  marginTop: '3rem',
  '& .MuiMenu-paper': {
    borderRadius: '.5rem',
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.colors.neutral700,
    color: theme.colors.white,
  },
  '& .MuiMenuItem-root': {
    paddingTop: '.625rem',
    paddingBottom: '.75rem',
    fontWeight: 400,
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      color: theme.colors.white,
      marginRight: '0.625rem',
    },
    '&:hover': {
      backgroundColor: theme.colors.menuHover,
    },
  },
  '& .MuiListItemText-root': {
    '&:hover': { color: theme.colors.white },
  },
}));

const UserMenuWrapper = styled(Box)<{ $canSwitchTenant: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding: 0.25rem;

  border: 1px solid
    ${({ $canSwitchTenant, theme }) =>
      $canSwitchTenant ? theme.colors.userMultitenantHighlight : theme.colors.headerBackground};
  border-radius: 2rem;

  &:hover {
    background-color: ${({ theme, $canSwitchTenant }) =>
      $canSwitchTenant ? theme.colors.headerBackgroundHover : 'inherit'};
  }

  & svg {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const TenantName = styled('span')`
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 0.5rem;
  padding-right: 0.625rem;
  color: ${({ theme }) => theme.colors.userMultitenantHighlight};
`;

const UserMenu = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  const { logout, user, userTenantId, userTenantName, userTenantOnLogin } = useAuthContext();
  const mobileOnlyLinks = useMobileOnlyLinks();
  const [tenancyMenuRef, setTenancyMenuRef] = useState<null | HTMLElement>(null);

  const { tenants } = useGetTenantsForCurrentUser();
  const canSwitchTenant = useMemo(() => !!tenants && tenants.length > 1, [tenants]);

  const [selectedTenant, setSelectedTenant] = useState<LoginTenant | null>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSelectedTenant(null);
    setTenancyMenuRef(null);
    setAnchorElUser(event.currentTarget);
    setUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setSelectedTenant(null);
    setTenancyMenuRef(null);
    setAnchorElUser(null);
    setUserMenuOpen(false);
  };

  const handleChooseTenant = useCallback((tenant: LoginTenant, event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedTenant(tenant);
  }, []);

  const handleToggleTenancyMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (tenancyMenuRef) {
        setTenancyMenuRef(null);
      } else {
        setTenancyMenuRef(event.currentTarget);
      }
      setSelectedTenant(null);
    },
    [tenancyMenuRef]
  );

  const handleCloseTenancyMenu = useCallback(() => {
    setSelectedTenant(null);
    setTenancyMenuRef(null);
  }, []);

  const tenantName = useMemo(() => {
    return userTenantOnLogin?.name ?? userTenantName;
  }, [userTenantName, userTenantOnLogin?.name]);

  return (
    <>
      <UserMenuWrapper $canSwitchTenant={canSwitchTenant}>
        <Box
          onClick={handleOpenUserMenu}
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <TenantName>{tenantName}</TenantName>
          <IconButton sx={{ p: 0 }}>
            <Avatar
              sx={{
                bgcolor: (theme) => theme.colors.blue300,
                color: 'black',
                fontSize: '0.875rem',
                fontWeight: 500,
                width: '2.1875rem',
                height: '2.1875rem',
              }}
            >
              {user?.user.firstName[0]}
              {user?.user.lastName[0]}
            </Avatar>
          </IconButton>
          {userMenuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Box>
        <UserDropdown
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          disableScrollLock={true}
          onClose={handleCloseUserMenu}
        >
          {canSwitchTenant && (
            <MenuItem sx={{ pr: 0 }} onClick={handleToggleTenancyMenu}>
              <ListItemText primary='Tenancy' />
              <ListItemIcon>
                <ArrowForwardIos />
              </ListItemIcon>
              <UserDropdown
                open={Boolean(tenancyMenuRef)}
                anchorEl={tenancyMenuRef}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={handleCloseTenancyMenu}
              >
                {tenants.map((tenant) => (
                  <MenuItem key={tenant.id} onClick={handleChooseTenant.bind(null, tenant)}>
                    <ListItemText primary={tenant.name} />
                    <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                      <CheckIcon
                        style={{
                          visibility:
                            tenant.id === (userTenantOnLogin?.id ?? userTenantId)
                              ? 'visible'
                              : 'hidden',
                        }}
                      />
                    </ListItemIcon>
                  </MenuItem>
                ))}
              </UserDropdown>
            </MenuItem>
          )}
          {settings?.map((setting) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <ListItemText primary={setting} />
            </MenuItem>
          ))}
          {mobileOnlyLinks?.map((sideNavLink) => (
            <PermissionCheck
              key={getUniqueKey(sideNavLink)}
              permission={
                privateRoutes.find((route) => route.path === sideNavLink.path)?.permission
              }
            >
              <MenuItem
                component={Link}
                to={sideNavLink.path}
                selected={location.pathname.includes(sideNavLink.path)}
                onClick={() => handleCloseUserMenu()}
                target={sideNavLink.external ? '_blank' : '_self'}
              >
                <ListItemText
                  primary={sideNavLink.title}
                  primaryTypographyProps={
                    location.pathname.includes(sideNavLink.path)
                      ? { style: { fontWeight: 500 } }
                      : {}
                  }
                  sx={{ color: 'white', marginRight: sideNavLink.external ? '2rem' : undefined }}
                />
                {sideNavLink.external && <ExternalLinkIcon fill='white' width={14} height={14} />}
              </MenuItem>
            </PermissionCheck>
          ))}
          <MenuItem
            onClick={() => logout()}
            // sx={{ mt: 1.25 }} // Not necessary if while settings array is empty
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary={'Log Out'} />
          </MenuItem>
        </UserDropdown>
      </UserMenuWrapper>
      {selectedTenant && (
        <TenancyModal
          tenantId={selectedTenant.id}
          tenantName={selectedTenant.name}
          open={!!selectedTenant}
          onClose={handleCloseUserMenu}
        />
      )}
    </>
  );
};

export default UserMenu;
