import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useClientContext } from 'context/client.context';
import Weighted from 'components/Elements/Typography/Weighted.styled';
import { Link } from 'react-router-dom';
import { LOGIN_LANDING_PAGE } from 'const/routes';
import { useHeaderLinks } from 'layout/layoutLinks';
import UserMenu from './UserMenu';

const headerNavSpacing = '3rem';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  backgroundColor: theme.colors.headerBackground,
  zIndex: theme.zIndex.drawer + 5,
  position: 'fixed',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const LogoWrapper = styled('div')`
  margin-right: ${headerNavSpacing};
  font-size: 1.5rem;
  padding-left: 1rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-left: 0;
    width: auto;
  }
`;

const HeaderLinkWrapper = styled('div')<{ $selected: boolean }>`
  display: inline;
  position: relative;
  border-bottom: solid 0.125rem ${(props) => props.theme.colors.headerBackground};

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
    font-weight: ${({ $selected }) => ($selected ? 600 : 500)};
  }

  &:hover {
    border-bottom: solid 0.125rem ${(props) => props.theme.colors.blue300};

    a {
      color: ${(props) => props.theme.colors.blue300};
    }
  }

  &:not(:last-child) {
    margin-right: ${headerNavSpacing};
  }

  &:before {
    content: '';
    position: absolute;
    left: -0.25rem;
    top: 1.5rem;
    width: calc(100% + 0.5rem);
    height: 0.125rem;
    border: ${({ $selected, theme }) => ($selected ? `1px solid ${theme.colors.white}` : 'none')};
    border-radius: 0.0625rem;
  }
`;

type Props = {
  selectedTab?: string;
};

const TopNav = ({ selectedTab }: Props): JSX.Element => {
  const { md, showSidebar, toggleShowSidebar } = useClientContext();
  const headerLinks = useHeaderLinks(selectedTab);

  return (
    <>
      <AppBar position='absolute' open={showSidebar}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={toggleShowSidebar}
            sx={{
              marginRight: '0.5rem',
              display: { md: 'none' },
              marginLeft: '0.5rem',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              sx={{ ...(!md && { textAlign: 'center' }) }}
            >
              <LogoWrapper>
                <Link
                  to={LOGIN_LANDING_PAGE}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <Weighted $fontWeight={300}>Re</Weighted>Store
                </Link>
              </LogoWrapper>
            </Typography>
            {md &&
              headerLinks.map((link) => (
                <HeaderLinkWrapper key={link.path} $selected={link.selected ?? false}>
                  <Link to={link.path}>{link.title}</Link>
                </HeaderLinkWrapper>
              ))}
          </Box>
          <UserMenu />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopNav;
